<template>
    <div class="category-card">
        <div class="category-card-image" :class="categoryClass">
            <div class="category-card-img__icon"><img :src="icon" :alt="label"></div>
        </div>
        <div class="category-card-content">
            <h5 class="category-card-content__title">{{ label }}</h5>
            <ul class="category-card-content__list">
                <li v-for="(option, index) in options" :key="index">
                <router-link :to="getLink(option.label)">{{ option.label }}</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import { getCategoryIcon } from '@/utils/lupe-helpers';

    export default {
        name: 'ExtendedKategorieCard',
        props: {
            item: {
                type: Object,
                required: true
            },
            mainCategory: {
                type: Object,
                required: true
            },
            subCategoryId: {
                type: Number,
                required: true
            },
            isLtr: {
                type: Boolean,
                default: true,
            }
        },
        computed: {
            label() {
                return this.item.label;
            },
            icon() {
                switch (this.label) {
                    case 'Bildung':
                    case 'Eğitim':
                    case 'Education':
                    case 'Освіта':
                    case 'تعليم':
                        return '/assets/icons/kategorien/bildung.png';
                        break;
                    case 'Freizeit':
                    case 'Boş zaman':
                    case 'Leisure':
                    case 'Вільний час':
                    case 'وقت الفراغ':
                        return 'assets/icons/kategorien/freizeit.png';
                        break;
                    case 'Ehrenamt & Engagement':
                    case 'Gönüllülük ve Bağlılık':
                    case 'Volunteering & Commitment':
                    case 'Волонтерство та відданість':
                    case 'التطوع والالتزام':
                        return '/assets/icons/kategorien/ehrenamt.png';
                        break;
                    case 'Beratung':
                    case 'Tavsiye':
                    case 'Counselling':
                    case 'Порада':
                    case 'نصيحة':
                        return '/assets/icons/kategorien/beratung.png';
                        break;
                    case 'Sprache':
                    case 'Dil':
                    case 'Language':
                    case 'Мова':
                    case 'لغة':
                        return '/assets/icons/kategorien/sprache.png';
                        break;
                    case 'Wohnen':
                    case 'İkamet':
                    case 'Housing':
                    case 'Проживати':
                    case 'مسكن':
                        return '/assets/icons/kategorien/wohnen.png';
                        break;
                    default:
                        return null;
                }
            },
            categoryClass() {
                switch (this.label) {
                    case 'Bildung':
                    case 'Eğitim':
                    case 'Education':
                    case 'Освіта':
                    case 'تعليم':
                        return 'bildung';
                        break;
                    case 'Freizeit':
                    case 'Boş zaman':
                    case 'Leisure':
                    case 'Вільний час':
                    case 'وقت الفراغ':
                        return 'freizeit';
                        break;
                    case 'Beratung':
                    case 'Tavsiye':
                    case 'Counselling':
                    case 'Порада':
                    case 'نصيحة':
                        return 'beratung';
                        break;
                    case 'Sprache':
                    case 'Dil':
                    case 'Language':
                    case 'Мова':
                    case 'لغة':
                        return 'sprache';
                        break;
                    case 'Ehrenamt & Engagement':
                    case 'Gönüllülük ve Bağlılık':
                    case 'Volunteering & Commitment':
                    case 'Волонтерство та відданість':
                    case 'التطوع والالتزام':
                        return 'ehrenamt';
                        break;
                    case 'Wohnen':
                    case 'İkamet':
                    case 'Housing':
                    case 'Проживати':
                    case 'مسكن':
                        return 'wohnen';
                    default:
                        return null;
                }
            },
            subCategory() {
                if (this.item !== null) {
                    return {
                        id: this.subCategoryId,
                        value: this.item.label
                    }
                }
                return null;
            },
            options() {
                return this.item.options;
            },
        },
        methods: {
            getLink(label) {
                const args = new Map();
                //args.set(this.mainCategory.id, [this.mainCategory.value]);
                args.set(this.subCategoryId, [this.subCategory.value]);
                args.set(this.item.field_type_id, [label]);
                return "/angebote?filters=" + encodeURIComponent(JSON.stringify(Array.from(args.entries())));
            },
        }
    }
</script>
<style lang="scss" scoped="">
    @import '@/scss/_variables.scss';

    .category-card {
        display: flex;
        flex-direction: row;
        border: none;
        min-height: 215px;

        @media(max-width: 991px) {
            min-height: auto;
        }

        &-image {
            padding-left: 20px;
            padding-right: 20px;

            border-radius: 0px 0px 0px 40px;
        }

        .beratung {
            .category-card-img__icon {
                border-color: #FA993F;
            }

            background-color: #FCCC9F;
        }

        .freizeit {
            .category-card-img__icon {
                border-color: #90C9FF;
            }

            background-color: #C7E4FF;
        }

        .bildung {
            .category-card-img__icon {
                border-color: #FCD770;
            }

            background-color: #FDEBB6;
        }

        .sprache {
            .category-card-img__icon {
                border-color: #ED4F58;
            }

            background-color: #F6A7AB;
        }

        .ehrenamt {
            .category-card-img__icon {
                border-color: #46CC8D;
            }

            background-color: #A4E6C7;
        }

        .wohnen {
            .category-card-img__icon {
                border-color: #7146CC;
            }

            background-color: #DED5F3;
        }

        &-img__icon {
            width: 50px;
            box-sizing: content-box;

            border: 3px solid;
            border-radius: 50%;
            background-color: #FFF;
            padding: 10px;
            margin-top: 20px;


        }

        &-content {
            align-self: flex-start;
            padding-left: 30px;
            padding-bottom: 20px;
            padding-top: 20px;
            padding-right: 10px;

            &__title {
                text-transform: uppercase;
                color: $black-color;
                margin-bottom: 15px;
                font-size: 18px;
            }

            &__list {
                li {
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    body[dir="rtl"] {
        .category-card {

            &-image {
                margin-left: 25px;
                border-radius: 0px 0px 40px 0px;
            }
        }
    }
</style>